import $ from 'jquery';

function headerLanguages() {
  const $els = $('.navigation-header-languages');
  if (!$els.length) return;

  $els.each((i, el) => {
    const $list = $('.navigation-header-languages__list', el);
    $(el).on('click', () => $list.toggle());
  });
}

function navigationToggle() {
  const $els = $('.navigation-mobile__toggle');
  if (!$els.length) {
    return;
  }

  $els.each((i, el) => {
    const $el = $(el);
    const $parent = $el.parents('.navigation-mobile');
    const $navi = $('.navigation-mobile__navi', $parent);

    $el.on('click', () => {
      $el.toggleClass('navigation-mobile__toggle--open');

      // Mark as expanded in aria
      $el.attr('aria-expanded', (i, attr) =>
        attr === 'true' ? 'false' : 'true'
      );

      $navi.toggleClass('navigation-mobile__navi--open');
    });
  });
}

$(function() {
  headerLanguages();
  navigationToggle();
});
