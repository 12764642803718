const DATA_TABS_FOR_TYPE = 'data-tabs-for-type';
const DATA_TABLE_TYPE = 'data-table-type';
const DATA_TABLE_NAME = 'data-table-name';
const DATA_TABLE_ACTIVE = 'data-table-active';

/** Initialise the rankings tables and tabs */
export function init(type) {
  if (document.readyState !== 'loading') {
    // Document is already complete/interactive; scripts are safe to run
    if (type) {
      initRankingsWithTabs(type);
    } else {
      initInternal();
    }
  } else {
    // Otherwise, add a listener, and run scripts when document content loads
    document.addEventListener('DOMContentLoaded', _ev => {
      if (type) {
        initRankingsWithTabs(type);
      } else {
        initInternal();
      }
    });
  }
}

function initInternal() {
  initRankingsWithTabs('individual');
  initRankingsWithTabs('federation');
  initRankingsWithTabs('team');
}

/**
 * Initialises tables of rankings that are controlled via tabs.
 * This involves finding the selectors and upgrading the markup, as well as adding event listeners.
 */
function initRankingsWithTabs(type) {
  const tabLinks = document.querySelectorAll(
    `[${DATA_TABS_FOR_TYPE}="${type}"] [data-tab]`
  );
  const tables = document.querySelectorAll(`[${DATA_TABLE_TYPE}="${type}"]`);

  // Find the initial active tab based on the location hash
  let initialTab;
  const windowLocationHash = window.location.hash;

  // Alias the empty hash to 'all' or 'men' if the table is 'team' rankingType
  // NOTE: This might be a bad assumption :)
  if (windowLocationHash === '') {
    switch (type) {
      // in case of olympics qualification page use olympics as default
      case 'olympics':
        initialTab = 'olympics';
        break;
      case 'team':
        initialTab = 'men';
        break;
      default:
        initialTab = 'all';
    }
  }

  // Otherwise, split at '#', and roll with it
  else {
    initialTab = windowLocationHash.split('#')[1];
  }

  // Show the initial new tab for the relevant tables
  showNewTab({
    tabLinks,
    tables,
    tab: initialTab,
  });

  // Add click listeners to each link
  tabLinks.forEach(link =>
    link.addEventListener('click', ev => {
      const target = ev.currentTarget;

      ev.preventDefault();

      // Perform filtering
      const tab = target.getAttribute('data-tab');

      // Change the document's hash
      if (tab !== undefined && tab !== null) {
        document.location.hash = tab;
      }

      // Show the new tab for each table
      showNewTab({tabLinks, tables, tab});
    })
  );
}

function showNewTab({tabLinks, tables, tab}) {
  if (tab !== null && tab !== undefined) {
    tabLinks.forEach(link => {
      // Remove the active indicator from each link
      link.removeAttribute('aria-current');

      // Set the active indicator on the active link
      if (link.matches(`[data-tab="${tab}"]`)) {
        link.setAttribute('aria-current', 'true');
      }
    });

    tables.forEach(table => {
      // Set all tables to hidden
      table.setAttribute(DATA_TABLE_ACTIVE, 'false');

      // Set the active table
      if (table.matches(`[${DATA_TABLE_NAME}="${tab}"]`)) {
        table.setAttribute(DATA_TABLE_ACTIVE, 'true');
      }
    });
  }
}
