import habitat from 'preact-habitat';
import {CountdownTo} from './CountdownTo';

function init() {
  const countdown = habitat(CountdownTo);

  countdown.render({
    selector: '.countdown', // Searches and mounts in <div class="some-class"></div>
    clean: true,
  });
}

init();
