import 'picturefill';
import $ from 'jquery';
import 'lazysizes';

// Provide global `jQuery` object for `magnific-popup`
// Don't overwrite global `$` which some browsers alias for querySelector
window.jQuery = $;

// Keep these as require not import to make sure they are not hoisted
require('magnific-popup');

// Clean imports
import * as ranking from 'site/_contentTypes/ranking/ranking';
import * as documents from 'site/_contentTypes/documentsComponent/documentsComponent';

// Imports with side-effects
import 'site/_contentTypes/highlightCarousel/highlightCarousel';
import 'site/_contentTypes/navigation/navigation';
import 'site/_contentTypes/playerProfile/playerProfile';
import 'site/_components/videoPlaylistCard';
import 'site/_contentTypes/refereesComponent/TabbedDifferenceTable';
import 'site/_contentTypes/countdown/countdown';
import 'site/_contentTypes/seasonCompetitionCalendar/seasonCompetitionCalendar';

ranking.init();
ranking.init('olympics');
documents.init();
