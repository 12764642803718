import $ from 'jquery';

$(function() {
  const $naviItems = $('.referees-component__referee-types__navi__item');
  const $descriptionTables = $('.referees-component__referee-types__item');

  const activeNaviTag = 'referees-component__referee-types__navi__item--active';
  const activeBlockTag = 'referees-component__referee-types__item--active';

  $naviItems.click(ev => {
    const $target = $(ev.currentTarget);

    $naviItems.map((i, elem) => {
      $(elem).attr('data-filter-tag') === $target.attr('data-filter-tag')
        ? $(elem).addClass(activeNaviTag)
        : $(elem).removeClass(activeNaviTag);
    });

    $descriptionTables.map((i, elem) => {
      $(elem).attr('data-filter-tag') === $target.attr('data-filter-tag')
        ? $(elem).addClass(activeBlockTag)
        : $(elem).removeClass(activeBlockTag);
    });
  });
});
