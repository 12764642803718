import {h} from 'preact';
import Countdown from 'react-countdown-now';
import {padStart} from 'lodash-es';

/**
 * A Countdown component written in vanilla Preact.
 * We avoided JSX to elide the tooling, and for fun.
 * But you could totally use JSX if you set up Webpack and Babel!
 */

const div = (props, children) => h('div', props, children);
const p = (props, children) => h('p', props, children);

const countdownBlock = (label, value) =>
  div({className: 'Countdown-Unit'}, [
    div({className: 'Countdown-List united'}, [
      Array.prototype.map.call(value, (ch, i) =>
        div({key: i, className: 'Countdown-ListItem'}, [ch])
      ),
      p({className: 'Countdown-Label roboto'}, [label]),
    ]),
  ]);

const countdownRenderer = ({days, hours, minutes, seconds}) =>
  div({className: 'Countdown-Timer'}, [
    !!days && [
      countdownBlock('Days', padStart(days.toString(), 2, '0')),
      div({className: 'Countdown-Sep'}, [':']),
    ],
    !!days && div({className: 'Countdown-Sep'}, ['\xA0']),
    countdownBlock('Hours', hours.toString()),
    div({className: 'Countdown-Sep'}, [':']),
    countdownBlock('Minutes', minutes.toString()),
    div({className: 'Countdown-Sep'}, [':']),
    countdownBlock('Seconds', seconds.toString()),
  ]);

const Heading = ({level, ...props}) => {
  // Use the appropriate heading
  const Hx = 'h' + Math.min(level, 6);
  return h(Hx, props);
};

export const CountdownTo = ({date, headingLevel}) =>
  div(
    {
      className:
        'Countdown-ns flex flex-column flex-row-m items-center items-end-m content-center flex-wrap',
    },
    [
      div({}, [
        div(
          {
            className:
              'mb2 mw6 pv1 ph2 bg-countdown-red countdown-black br2 tc center',
          },
          [
            h(
              Heading,
              {
                className: 'f6 f5-m fw7 roboto',
                level: headingLevel,
              },
              ['Countdown: Paris 2024']
            ),
          ]
        ),
        h(Countdown, {date, renderer: countdownRenderer}),
      ]),
    ]
  );
