import $ from 'jquery';

$(function() {
  const $playlistElements = $('.playlist-card__playlist__list__item');
  const $playerContainer = $('.playlist-card__player');
  const $playerElement = $('#playlistPlayer');
  const activeClass = 'playlist-card__playlist__list__item--active';

  $playlistElements.map((i, elem) => {
    $playerElement.attr('src').includes($(elem).attr('id')) &&
      $(elem).addClass(activeClass);
  });

  $playlistElements.click(ev => {
    ev.preventDefault();
    const $target = $(ev.currentTarget);
    const targetUrl = `https://www.youtube.com/embed/${$target.attr('id')}`;

    const $replacementHtml = `<iframe class="playlist-card__player__frame" id="playlistPlayer" src='${targetUrl}'></iframe>`;

    $playlistElements.removeClass(activeClass);
    $target.addClass(activeClass);
    $playerContainer.html($replacementHtml);
  });
});
