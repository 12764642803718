import $ from 'jquery';
import 'slick-carousel';

const preventDefault = fn => (ev, ...args) => {
  ev.preventDefault();
  return fn(ev, ...args);
};

$(function() {
  const $els = $('.highlight-carousel');
  if (!$els.length) return;

  $els.each((i, el) => {
    const $el = $(el);
    const $list = $('.highlight-carousel__list', $el);
    if (!$list.length) return;

    const opts = {
      infinite: typeof $list.attr('data-no-infinite') === 'undefined',
      arrows: typeof $list.attr('data-no-arrows') === 'undefined',
      dots: typeof $list.attr('data-no-dots') === 'undefined',
    };
    $list.slick(opts);

    if (!opts.arrows) {
      $('.slick-prev', $el).on(
        'click',
        preventDefault(() => $list.slick('slickPrev'))
      );
      $('.slick-next', $el).on(
        'click',
        preventDefault(() => $list.slick('slickNext'))
      );
    }
  });
});
