const DATA_CONTAINER_NAME = 'data-container-name';
const DATA_CONTAINER_ACTIVE = 'data-container-active';

/** Initialise the documents tabs */
export function init() {
  if (document.readyState !== 'loading') {
    // Document is already complete/interactive; scripts are safe to run
    initInternal();
  } else {
    // Otherwise, add a listener, and run scripts when document content loads
    document.addEventListener('DOMContentLoaded', _ev => {
      initInternal();
    });
  }

  function initInternal() {
    initDocumentsTabs();
  }
}

/**
 * Initialises document containers that are controlled via tabs.
 * This involves finding the selectors and upgrading the markup, as well as adding event listeners.
 * In this context, a container is a div that, according to contentful entries, wraps one or more tables pertinent to the same topic.
 */

function initDocumentsTabs() {
  const tabLinks = document.querySelectorAll(`[data-tab]`);
  const tabContainers = document.querySelectorAll(`[data-container-name]`);

  // Find the initial active tab based on the location hash
  let initialTab;
  const windowLocationHash = window.location.hash;

  // If we don't have a hash on initial load, pick the first tab in the list
  if (windowLocationHash === '') {
    initialTab = tabLinks[0].getAttribute('data-tab');
  }

  // Otherwise, split at '#', and roll with it
  else {
    initialTab = windowLocationHash.split('#')[1];
  }

  // Show the initial new tab for the relevant container
  showNewTab({
    tabLinks,
    tabContainers,
    tab: initialTab,
  });

  // Add click listeners to each link
  tabLinks.forEach(link =>
    link.addEventListener('click', ev => {
      const target = ev.currentTarget;

      ev.preventDefault();

      // Perform filtering
      const tab = target.getAttribute('data-tab');

      // Change the document's hash
      if (tab !== undefined && tab !== null) {
        document.location.hash = tab;
      }

      // Show the new tab for each container
      showNewTab({tabLinks, tabContainers, tab});
    })
  );
}

function showNewTab({tabLinks, tabContainers, tab}) {
  if (tab !== null && tab !== undefined) {
    tabLinks.forEach(link => {
      // Remove the active indicator from each link
      link.removeAttribute('aria-current');

      // Set the active indicator on the active link
      if (link.matches(`[data-tab="${tab}"]`)) {
        link.setAttribute('aria-current', 'true');
      }
    });

    tabContainers.forEach(container => {
      // Set all containers to hidden
      container.setAttribute(DATA_CONTAINER_ACTIVE, 'false');

      // Set the active container
      if (container.matches(`[${DATA_CONTAINER_NAME}="${tab}"]`)) {
        container.setAttribute(DATA_CONTAINER_ACTIVE, 'true');
      }
    });
  }
}
