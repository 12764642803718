import $ from 'jquery';

// Magnific-popup requires that youtube urls are in format "http://www.youtube.com/watch?v=7HKoqNJtMTQ"
// @see http://dimsemenov.com/plugins/magnific-popup/documentation.html#iframe-type
function getYoutubeUrl(el) {
  // Parse id from url
  const videoId = el.getAttribute('data-video-id');
  if (videoId) return `https://www.youtube.com/watch?v=${videoId}`;
}

$(function() {
  $('.player-profile__media-thumbnail').each(function(i, el) {
    el.href = getYoutubeUrl(el);
    $(el).magnificPopup({
      type: 'iframe',
      disableOn: 640,
    });
  });
});
